import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AxiosError } from 'axios'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Provider as ReduxProvider } from 'react-redux'
import SwiperCore from 'swiper'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import 'swiper/swiper-bundle.css'

import {
  GlobalLoadingSpinnerProvider,
  InitializationProvider,
  PageIdContextProvider,
} from '@/Providers'
import { Error, FrameLayout } from '@/components'
import DevTools from '@/components/DevTools'
import { wrapper } from '@/features/store'
import { CheckPageId } from '@/routes'
import '@/styles/index.scss'

SwiperCore.use([Pagination, Autoplay, Navigation])

const devToolEnabled = process.env.NEXT_PUBLIC_DEBUG_VIEW === 'Y'

function App({ Component, ...rest }: AppProps) {
  const [error, setError] = useState<AxiosError>()
  const { store, props } = wrapper.useWrappedStore(rest)
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 0,
            refetchOnWindowFocus: false,
            cacheTime: 5 * 60 * 1000,
            staleTime: 5 * 60 * 1000,
            useErrorBoundary: true,
          },
        },
        queryCache: new QueryCache({
          onError: (error) => {
            console.log(error)
          },
        }),
      })
  )

  const handleError = useCallback((error: AxiosError) => {
    setError((prev) => {
      if (!prev) return error
      if (prev.response?.status !== error.response?.status) return error
    })
    // useErrorBoundary: true 설정된 에러 트래킹
    // trackErrorLogs(error, 'tscore:error:errorBoundary')
  }, [])

  return (
    <>
      <Head>
        <title>마이</title>
        <meta
          name="description"
          content="마이 서비스"
        />
        <meta
          name="apple-mobile-web-app-capable"
          content="yes"
        />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="default"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0, viewport-fit=cover"
        />
        <meta
          httpEquiv="Cache-control"
          content="no-cache,no-store,must-revalidate"
        />
        <link
          rel="icon"
          href="/favicon.ico"
        />
      </Head>

      <ReduxProvider store={store}>
        <InitializationProvider>
          <QueryClientProvider client={queryClient}>
            <PageIdContextProvider>
              <ErrorBoundary
                onError={(error) => handleError(error as AxiosError)}
                fallback={<Error error={error} />}
              >
                <CheckPageId>
                  {/*<ToastProvider>*/}
                  <GlobalLoadingSpinnerProvider offsetTop={'FULL'}>
                    {/*<GlobalModalManagerProvider>*/}
                    <FrameLayout>
                      <Component {...props.pageProps} />
                    </FrameLayout>
                    {devToolEnabled && <DevTools />}
                    <ReactQueryDevtools position={'bottom-right'} />
                    {/*</GlobalModalManagerProvider>*/}
                  </GlobalLoadingSpinnerProvider>
                  {/*</ToastProvider>*/}
                </CheckPageId>
              </ErrorBoundary>
            </PageIdContextProvider>
          </QueryClientProvider>
        </InitializationProvider>
      </ReduxProvider>
    </>
  )
}

export function ErrorNotifier({ error }: { error: AxiosError | undefined }) {
  // 동일한 에러가 여러번 dispatch 됐을때 무시하도록 error.code만 확인.
  const errorCode = error?.response?.status

  useEffect(() => {
    if (error) {
      let message: ReactNode

      switch (errorCode) {
        case 401:
          message = (
            <>
              사용자 인증정보가 변경되었습니다.
              <br />
              잠시 후 다시 시도해주세요.
            </>
          )
          break
        default:
          message = (
            <>
              {' '}
              일시적으로 서비스를
              <br /> 이용할 수 없습니다.
              <br /> <p className="sub">잠시 후 다시 시도해주세요.</p>
            </>
          )
          break
      }
    }
  }, [error, errorCode])
}

export default App
