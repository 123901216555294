import { AxiosError, AxiosHeaders, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { isUndefined } from 'lodash-es'

import { AxiosConfig, initAxios, mockHost } from './axios'

export type RequestConfig = Omit<AxiosRequestConfig, 'headers'> & {
  headers: AxiosHeaders
}
export type Response<ResData> = AxiosResponse<ResData>

export interface CoreConfig<ResData = any> {
  axiosList: AxiosInstance[]
  onRequest?: (
    config: RequestConfig,
    axios: AxiosInstance
  ) => Promise<RequestConfig> | RequestConfig
  onResponse?: (
    response: Response<ResData>,
    axios: AxiosInstance
  ) => Promise<Response<ResData>> | Response<ResData>
  onAccessKeyChanged: (newAccessKey: string) => void
  onAccessKeyExpired: () => void
}

type myHeaders = {
  service?: string
  authorization?: string
  deviceId?: string
  os?: string
  appversion?: string
}

type ClientConfig = Omit<CoreConfig, 'axiosList' | 'onAccessKeyExpired' | 'onAccessKeyChanged'> &
  AxiosConfig & {
    headers?: myHeaders
    onAccessKeyChanged: (newAccessKey: string) => Promise<any> | void
    onAccessKeyExpired?: (error: AxiosError, axios: AxiosInstance) => Promise<void> | void
    onError?: (error: AxiosError, axios: AxiosInstance) => Promise<any> | void
  }

function initMockClient({
  onAccessKeyChanged,
  onAccessKeyExpired,
  onRequest = async (config) => config,
  onResponse,
  onError,
  ...envConfig
}: ClientConfig) {
  initAxios(envConfig)

  mockHost.interceptors.request.use((config) => {
    config.headers = config.headers || ({} as AxiosHeaders)
    return onRequest(config as RequestConfig, mockHost)
  })

  mockHost.interceptors.response.use(
    async (response) => {
      const { headers } = response
      const newAccessKey = headers['ak-refresh']
      if (newAccessKey) {
        await onAccessKeyChanged(newAccessKey)
      }
      return onResponse ? onResponse(response, mockHost) : response
    },
    async (error) => {
      if (onAccessKeyExpired && error.response?.status === 401) {
        const result = await onAccessKeyExpired(error, mockHost)
        return isUndefined(result) ? Promise.reject(error) : result
      }

      if (onError) {
        const result = await onError(error, mockHost)
        return isUndefined(result) ? Promise.reject(error) : result
      }
      return Promise.reject(error)
    }
  )
}

export { initMockClient }
