import { AdType } from '@tmap-web-lib/remote-api-client/src/frontman'
import { TmapLogBuilder } from '@tmap-web-lib/tmap-log-client'
import { useCallback } from 'react'

import { useOpenService } from './use-open-service'
import { useOpenServiceUrl } from './use-open-service-url'

export interface TrackingValues {
  orientation: number
  vimpressionId: string
  trackingUrl: string
  materialId: string
}

function useAdsManager() {
  const openService = useOpenService()
  const openServiceUrl = useOpenServiceUrl()

  const initTrackingValues = useCallback(async (data: AdType) => {
    //@todo orientation 변경하기
    const orientation = 1
    const adItem = data
    let trackingUrl,
      materialId,
      vimpressionId = ''

    const trackingInfo = adItem.trackingEventUrls.find(
      (trackingInfo) => trackingInfo.key === 'vimp'
    )
    const material = adItem.materials[0]

    if (trackingInfo && material) {
      await import('uuid').then(({ v4 }) => {
        if (v4()) {
          vimpressionId = v4()
        }
      })
      trackingUrl = trackingInfo.value
      materialId = material.id
      return {
        orientation,
        vimpressionId,
        trackingUrl,
        materialId,
      } as TrackingValues
    }
  }, [])

  const handleBanner = useCallback(
    (ad: AdType, eventLog: TmapLogBuilder, actionId: string) => {
      if (ad.landingUrl) {
        openService(ad.landingUrl)
        eventLog.set('action_id', actionId).setCustom('ad_code', ad.materials[0].id).send()
      }
    },
    [openService]
  )

  return {
    initTrackingValues,
    handleBanner,
  }
}

export { useAdsManager }
