import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { getSearchParams } from '@tmap-web-lib/utils'
import { useCallback } from 'react'
import Url from 'url-parse'

function useOpenService() {
  return useCallback((srcUrl: string) => {
    if (!srcUrl) {
      return
    }

    const urlObject = new Url(srcUrl)
    const scheme = urlObject.protocol
    const serviceName = urlObject.hostname
    if (scheme.startsWith('tmap')) {
      const params = getSearchParams(urlObject.query || '')
      TmapApp.openServiceByName({ serviceName, jsonData: params })
    } else {
      TmapApp.openBrowser({ url: srcUrl })
    }
  }, [])
}

export { useOpenService }
