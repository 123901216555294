import { ModalPortal } from '@tmap-web-lib/modal-manager'
import { useContext, useMemo } from 'react'
// eslint-disable-next-line no-restricted-imports
import { usePromiseTracker } from 'react-promise-tracker'

import { LoadingSpinner } from '@/components'

import { GlobalLoadingSpinnerContext } from './GlobalLoadingSpinnerProvider'

export function GlobalLoadingSpinner() {
  const { promiseInProgress } = usePromiseTracker()
  const { options } = useContext(GlobalLoadingSpinnerContext)

  const top = useMemo(() => {
    switch (options.offsetTop) {
      case 'FULL':
        return '0'
      case 'WITHOUT_HEADER':
        return '56px'
      default:
        return options.offsetTop
    }
  }, [options.offsetTop])

  return (
    <ModalPortal elementId={'global-loading-spinner'}>
      <LoadingSpinner
        position={'fixed'}
        show={promiseInProgress}
        top={top}
      />
    </ModalPortal>
  )
}
