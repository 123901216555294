export const schemeUrl = {
  profile: 'tmap://setting-myinfo',
  point: 'tmap://paypoint',
  biz_place: 'tmap://biz-place',
}

export const envServiceUrl = {
  tmapBizInfo: {
    dev: 'https://tmobi-board-dev.tmapmobility.com/BOARD-STATIC/61',
    stg: 'https://tmobi-board-stg.tmapmobility.com/BOARD-STATIC/90',
    prd: 'https://board.tmapmobility.com/BOARD-STATIC/9',
  },
}

export const staticBizPlaceBanner = { isShow: true, url: 'tmap://biz-place' }

export interface GroupListType {
  name: string
  url: string
}

export interface ServiceListType {
  groupName: string
  groupCode: string
  groupList: GroupListType[]
}

export const staticServiceList = [
  {
    groupName: '결제관리',
    groupCode: 'pay_manage',
    groupList: [
      {
        name: '이용내역',
        url: 'tmap://general?pageid=usage-history',
      },
      {
        name: '결제 관리',
        url: 'tmap://general?pageid=usage-history',
      },
      {
        name: '제휴 쿠폰',
        url: 'tmap://coupon',
      },
    ],
  },
  {
    groupName: '서비스 모아보기',
    groupCode: 'service_list',
    groupList: [
      {
        name: '전체서비스',
        url: '',
      },
      {
        name: '비즈플레이스',
        url: 'tmap://biz-place',
      },
      {
        name: '연구소',
        url: 'tmap://setting-laboratory',
      },
    ],
  },
  {
    groupName: '고객지원',
    groupCode: 'customer_support',
    groupList: [
      {
        name: '고객센터',
        url: 'tmap://cs',
      },
      {
        name: '공지사항/이벤트',
        url: 'tmap://communication',
      },
      {
        name: '장소제안',
        url: 'tmap://tip-off',
      },
      {
        name: '약관',
        url: '',
      },
    ],
  },
]
